<template>
  <div id="site-settings-contact-us">
    <el-form
      ref="form"
      :model="detailForm"
      :rules="rules"
      label-position="top"
      @submit.native.prevent="handleSubmit"
    >
      <el-row class="header" type="flex" justify="space-between" align="middle">
        <h4>{{ $t("EDIT_CONTACT_US_DETAILS") }}</h4>
        <el-row type="flex" justify="end" align="middle" class="status-row">
          <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
          <el-select 
            v-model="detailForm.status"
            :class="getStatusClass(detailForm.status) + (isMobile ? ' w-100': '')">
            <el-option
              v-for="item in allSelectableStatusListFor(detailForm.status)"
              :key="item.value"
              :label="$t(item.value)"
              :value="item.value"
            />
          </el-select>
        </el-row>
      </el-row>

      <el-tabs
        tab-position="top"
        class="detail-language-options"
        type="card"
        stretch
        v-model="selectedLanguage"
        :before-leave="changeLang"
      >
        <el-tab-pane v-for="el in getLangList()" :key="el.code" :name="el.code">
          <el-row type="flex" align="middle" slot="label">
            <img
              class="custom-icon icon-lang mini"
              :src="getLangFlagImage(el.code)"
            />
            <span 
              v-if="!isMobile || el.code === 'hi' || el.code === 'ta'"
            >{{ el.label }}</span>
          </el-row>
        </el-tab-pane>

        <el-form-item
          prop="name"
          class="is-required"
        >
          <html-editor
            v-loading="isLoading"
            :key="editorId" 
            :modelValue.sync="detailBody" 
            :length.sync="detailLength"
          />
        </el-form-item>
      </el-tabs>

      <page-footer
        :isLoading="isLoading"
        :handleBackTo="exit"
        :handleSubmit="() => {}"
        :submitBtnText="$t('SAVE')"
      />
    </el-form>
  </div>
</template>

<script>
import HTMLEditor from "@/components/quill/html-editor";
import PageFooter from "@/components/page-footer.vue";
import {
  getContactUsList,
  createContactUs,
  updateContactUs,
} from "@/api/site-settings"
import { generalMixin } from "@/utils/general-mixin";
import cloneDeep from "lodash/cloneDeep"
import i18n from "@/locales";
import { removeAllHtmlTags } from "@/utils/helpers"
import { mapGetters } from "vuex";

const formFields = {
  status: "ACTIVE",
  type: "contact_us",
  translationList: [],
};

export default {
  name: "ContactUs",
  components: {
    "html-editor": HTMLEditor,
    PageFooter
  },
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      editorId: 0,
      detailLength: 0,
      selectedLanguage: this.locale || "en",
      detailForm: cloneDeep(formFields),
      rules: {
        name: [
          {
            message: i18n.t("FIELD_REQUIRED"),
            trigger: "blur",
            validator: this.validateBody
          },
        ]
      },
    }
  },
  computed: {
    ...mapGetters("app", ["getLangFlagImage", "getLangList"]),
    detailBody: {
      get() {
        const locale = this.selectedLanguage;
        const html = this.getNameFromTransList(
          this.detailForm,
          "",
          locale
        );
        return html;
      },
      set(newValue) {
        const locale = this.selectedLanguage
        let langIndex = this.detailForm.translationList.findIndex(
          (el) => el.language.code == locale
        );

        if (newValue) {
          let obj = this.detailForm.translationList[langIndex]
          if (obj == null) {
            obj = {
              name: newValue,
              language: this.getLangList().find((el) => el.code == locale),
            };
            langIndex = this.detailForm.translationList.length;
          } else {
            obj.name = newValue;
          }
          this.$set(this.detailForm.translationList, langIndex, obj);
        } else {
          // Prevent empty content in translation array
          this.articleForm.translationList = this.articleForm.translationList.filter(el => {
            return el.language.code != locale
          })
        }
      },
    },
  },
  methods: {
    exit() {
      this.goTo("/bo/site-settings");
    },
    validateBody(_, __, callback) {
      const list = this.detailForm.translationList
      const elementWithNameIndex = list.findIndex((el) => {
        if (el.name) {
          const html = el.name
          const pureInput = removeAllHtmlTags(html)
          return pureInput !== ""
        }
        return false
      })

      if (
        list == null 
        || list.length === 0
        || elementWithNameIndex === -1
      ) {
        callback(this.$t("FIELD_REQUIRED"))
      } else {
        callback()
      }
    },
    getDetail() {
      this.isLoading = true
      getContactUsList().then((res) => {
        if (res.status !== "ok") return
        const detail = res.result.list[0]
        if (detail != null) {
          this.detailForm = cloneDeep(detail)
          this.editorId++
        }
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    changeLang(_, oldV) {
      this.editorId++;
      // Prevent empty content in article body
      if (this.detailLength === 0) {
        const filteredList = this.detailForm.translationList.filter(
          (el) => el.language.code != oldV 
        );
        this.detailForm.translationList = filteredList
      }
    },
    handleSubmit() {
      this.$refs["form"].validate(valid => {
        if (!valid) return

        let apiToCall = createContactUs
        if (this.detailForm.id != null) {
          apiToCall = updateContactUs
        }

        this.isLoading = true
        const postData = cloneDeep(this.detailForm)
        apiToCall(postData).then((res) => {
          if (res.status !== "ok") return

          this.$notify({
            type: 'success',
            message: this.$t("UPDATE_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
          this.exit()
          this.$store.dispatch('app/getAllSiteSettings') // to reflect the changes
        })
        .finally(() => {
          this.isLoading = false
        })

      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getDetail()
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/detail.scss";

#site-settings-contact-us {
  @include detail-language-options;
  background-color: $white;
  padding: 1.5rem;

  .header {
    margin-bottom: 1rem;
  }

  .status-row {
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .icon-lang {
    margin-right: 5px;
  }
}
</style>